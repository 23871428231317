import { useState, useEffect } from 'react'
import loader from './googleMapsLoader'

const Map = ({ address }) => {
  const [map, setMap] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true) // Set loading state to true when starting to load map
    loader.load().then(() => {
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          const mapOptions = {
            center: results[0].geometry.location,
            zoom: 18,
          }
          const newMap = new window.google.maps.Map(
            document.getElementById('map'),
            mapOptions
          )
          setMap(newMap)
          setIsLoading(false)
        }
      })
    })
  }, [address])

  return (
    <div style={{ position: 'relative', minHeight: '400px' }}>
      {isLoading && <div>Loading...</div>}
      <div
        alt='Google map'
        id='map'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      ></div>
    </div>
  )
}

export default Map
